import React from "react"
import { graphql } from "gatsby"
import Title from "../../components/title"
import Layout from "../../components/layout"
import StyledHero from "../../components/styledHero"
import styles from "../../css/offer.module.css"
import SEO from "../../components/seo"

const Huebner = ({ data, location }) => {
  return (
    <Layout location={location.pathname}>
      <SEO title="Leistungsangebot Hübner Management GmbH" />
      <StyledHero img={data.huebnerBcg.childImageSharp.fluid} />
      <section className={styles.offer}>
        <Title title="Leistungsangebot" subtitle="Hübner Management GmbH" />
        <div className={styles.offerCenter}>
          <header>
            <a
              href="mailto:britta.huebner@huebner-interim.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.partnerLogo}
                src="/huebner.png"
                alt="Hübner Management GmbH Logo"
              />
            </a>
            <h4>Pro Bono Leistungsangebot Hübner Management GmbH</h4>
          </header>
          <article className={styles.offerText}>
            <p>
              In der Krise sind besondere Anforderungen an die
              Liquiditätssteuerung und -planung gestellt. Wir bieten Ihnen eine
              kurze Durchsicht der vorhandenen Unterlagen an und geben Ihnen
              Empfehlungen für den Aufbau bzw. Optimierung Ihres
              Liquiditätsmanagements.
            </p>
          </article>
          <footer>
            <a
              href="mailto:kontakt@vscovidunited.de"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-primary"
            >
              Angebot anfordern
            </a>
          </footer>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    huebnerBcg: file(relativePath: { eq: "showroomBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Huebner
